<template>
  <div
    class="banner_bg"
    :style="{ 'background-image':details.certificationStatus&&details.certificationStatus==6?'url(' + bannerBg.yes + ')':'url(' + bannerBg.not + ')' }"
  >
    <div class="title_box">
      <h5>{{details.courseName}}</h5>
      <p>{{details.description}}</p>
    </div>
    <!-- 2/6 -->
    <div class="btn_box" @click="$router.push({path:'/VCertificateApply',query:{id:details.id}})" v-show="details.certificationStatus!=null&&(details.certificationStatus==2||details.certificationStatus==6)">
        <img src="@/assets/imgs/cer/btn_before_icon.png" width="21" height="16"/>
        立即报名
    </div>
    <div class="steps">
      <div class="flow_box">
        <div class="flow_item" v-for="(item, index) in steps" :key="index">
          <div
            class="step_circle-container"
            v-if="item.status==0||item.status==1"
            style="border: none"
          >
            <img
              src="@/assets/imgs/cer/steps_yes.png"
              width="100%"
              height="100%"
              alt=""
            />
          </div>
          <div class="step_circle-container" v-else></div>
          <div class="step_line" v-if="index < 4">
            <span
              class="step_line-cont"
              :style="{ width: item.status==0 ? '100%' :item.status==1?'50%' :'0%' }"
            ></span>
          </div>
          <p class="step_title" :style="{ color: item.status<=1? '#fff' : '' }">
            {{item.label}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  data() {
    return {
      bannerBg: {
        yes: require("@/assets/imgs/cer/cer_yes_banner.png"),
        not: require("@/assets/imgs/cer/cer_not_banner.png"),
      },
      //流程标签
      flowArr: ["理论认证", "实操报名", "上传作品", "申请答辩", "获得证书"],
    };
  },
  props:['details'],
  computed:{
    steps(){
        let arr=[];
        if(this.details){
          /*
            status:
              0:进度已完成
              1:进度刚好等于当前高亮
              2:未完成
          */
          if(this.details.certificationStatus&&this.details.certificationStatus>1){
            arr=this.flowArr.map((item,index)=>{
              let num=Number(index)+2;
              return {label:item,status:num<this.details.certificationStatus?0:num==this.details.certificationStatus?1:2};
            })
          }else{
            arr=this.flowArr.map((item)=>{
              return {label:item,status:2};
            })
          }
        }
        return arr;  
    }

  }
};
</script>
<style lang="scss" scoped>
.banner_bg {
  width: 1200px;
  height: 239px;
  margin: 19px auto 0 auto;
  background-position-x: 0px;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  .title_box{
    position: absolute;
    top:30px;
    left: 320px;
    h5{
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      min-width:auto;
      max-width:400px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    p{
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 3px;
      width: 400px;
      height: 60px;
      line-height:30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
  }
  .btn_box{
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
    border-radius: 999px;
    border: 1px solid #FFFFFF;
    padding: 12px 28px;
    position: absolute;
    top: 28px;
    right: 270px;
    display: inline-block;
    cursor: pointer;
  }
  .steps {
    width: 934px;
    position: absolute;
    top:162px;
    right: 10px;
    .flow_box {
      width: 100%;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      margin: 0 auto;
      padding-bottom: 22px;
      .flow_item {
        position: relative;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        color: #969799;
        font-size: 14px;
        float: left;
        color: #323233;
        .step_circle-container {
          position: absolute;
          top: 0;
          left: calc(50% - 12px);
          z-index: 1;
          width: 24px;
          height: 24px;
          border: 3px solid #AEB7FF;
          border-radius: 50%;
          box-sizing: border-box;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        .step_line {
          background-color: #AEB7FF;
          left:calc(50% + 19px);
          top: 0;
          width: 76%;
          height: 2px;
          position: absolute;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s;
          .step_line-cont {
            background-color: #fff;
            left: 0;
            top: -1px;
            height: 4px;
            position: absolute;
          }
        }
        .step_title {
          margin-top: 22px;
          font-size: 15px;
          transform: translateX(0);
          color: #AEB7FF;
          text-align: center;
        }
      }
    }
  }
}
</style>